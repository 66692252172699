import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
  Box,
  Tooltip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Card,
  CardHeader,
  Stack,
  Modal,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { handleDownload } from "./downloadFile";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import otherFile from '../../assets/img/file-icon.png'

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ViewInvoiceModal({ open, handleClose, Invoice }) {
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <IconButton
          onClick={()=>handleClose()}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <HighlightOffIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Invoice
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box>
            <Box className="m-auto p-relative">
              <Tooltip
                title={Invoice}
                placement="top"
                className="uploadedfilename"
              >
                <img
                  width={30}
                  height={30}
                  src={otherFile}
                  className="my-auto"
                  alt="Other File"
                  style={{ marginRight: "3px" }}
                />
                <Typography>Invoice</Typography>
                <DownloadForOfflineRoundedIcon
                  className="my-auto"
                  onClick={() =>
                    handleDownload(new Date() + "invoice", Invoice)
                  }
                />
                <FullscreenIcon
                  className="my-auto"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_BASE_URL}${Invoice}`)
                  }
                />
              </Tooltip>
            </Box>
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
}
