import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Tooltip, TextareaAutosize } from "@mui/material";
import { convertToFormData } from "../ConvertToFormData";
import { useDispatch, useSelector } from "react-redux";
import { gfcLayout } from "../../store/layout/gfc";
import { handleDownload } from "../reusable/downloadFile";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import GfcRequestForRevision from "../reusable/GfcRequestForRevision.jsx";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png';
import cad from '../../assets/img/cad.png';
import { showToast } from "../../toast/toast.jsx";
import { approveGFC } from "../../store/layout/approveGFC.js";
import DoneIcon from '@mui/icons-material/Done';
export default function Gfc() {
    localStorage.removeItem("isRevisedViewClick");
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const gfcId =useSelector((state) => state?.gfcReducer?.result);
    const[approveByAdmin, setApproveByAdmin] = useState();
    const [isSentFeedBack, setIsSentFeedBack] = useState(false);
    const [approved, setApproved] = useState(false);
    const {result : revisedGFCData} = useSelector((state) => state?.revisedGFCViewReducer);
    const {result : GFCData, loading : GFCLoading} = useSelector((state) => state?.gfcReducer)
    const [data, setData] = useState(localStorage.getItem("isRevisedGFCViewClick") == 1 ? revisedGFCData : GFCData);   
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        getGfcLayout()
    };

    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.id
        };
        const formData = convertToFormData(formD);
        try {
            const response = await dispatch(gfcLayout(formData));
        } catch (error) {
        }
    }; 
    
    const [loading, setLoading] = useState(false);
    const approveGFCAction = async () => {
        setLoading(true);
        const formData = convertToFormData({ 'gfc_id': gfcId?.id });
        try {
            const response = await dispatch(approveGFC(formData)); 
            showToast(response?.payload?.message, 1);
            setApproved(true);
            setIsSentFeedBack(true);
            setLoading(false);
            getGfcLayout();
        } catch (error) {
            console.error("Error in sending data to Kin:", error);
        }
    };

    useEffect(() => {
        const isRevisedGFCViewClick = localStorage.getItem("isRevisedGFCViewClick");
        setData(isRevisedGFCViewClick === '1' ? revisedGFCData : GFCData);
    }, [revisedGFCData, GFCData]);

    useEffect(() => {
        if(data?.revision_request===true || data?.approve_by_dealor===true){
          setIsSentFeedBack(true);
        }
    },[data])

    useEffect(() => {
        if(data?.approve_by_dealor===true){
            setApproved(true);
        }
    },[data])

    useEffect(() => {
        getGfcLayout();
    }, []);

    useEffect(()=>{
        setApproveByAdmin(gfcId)
    },[gfcId])

    return (
        <>
        {approveByAdmin?.approve_by_admin == true ? (    <>
            <Box className="h-100 d-flex flex-column justify-content-between">
            <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Typography variant="h6" className="fs-17  fw-400">GFC Received</Typography>
                    </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                        <Box sx={{ width: "213px" }}>
                        {data?.gfc_pdf?.map((data) => (

                            <Box className="text-center p-relative">
                                <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                    <Typography>
                                        {'PDF File'}
                                    </Typography>
                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'gfc', data.files)} />
                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data.files}`)} />
                                </Tooltip>
                            </Box>
                        ))}
                        </Box>
                        
                    

                        <Box sx={{ width: "213px" }}>
                        {data?.gfc_cad?.map((data) => (
                                <>
                                    <Box className="text-center p-relative">
                                        <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                            <img width={40} height={40} src={cad} className="my-auto" />
                                            <Typography>
                                                {'CAD File'}
                                            </Typography>
                                            <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'gfc_cad', data.files)} />
                                            <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data.files}`)}/>
                                        </Tooltip>
                                    </Box>
                                </>
                            ))}
                        </Box>
                    
                    
                </Box>

                        

                           
                    </Grid>

                </Grid>
                <Box className="w-100" sx={{ marginTop: '5px' }}>
                                <Box className="d-flex" sx={{ gap: '18px' }}>
                                    <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment </Typography>
                                </Box>
                                <Box sx={{ height: "fit-content" }}>
                                    <TextareaAutosize
                                        disabled
                                        value={data?.comment || ""}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="comment"
                                        style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }}
                                        className="bg-white"
                                        minRows={6} />
                                </Box>
                            </Box>
                <Grid container spacing={2} sx={{marginTop:"15px"}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                        {/* <Button className="black-btn" onClick={approveGFCAction}>Approve</Button> */}
                        <Button
                            className="black-btn" 
                        
                            onClick={() => approveGFCAction()}
                            disabled={approved || isSentFeedBack || loading}
                        >
                            {approved ? "Approved" : "Approve"}
                            {approved && <DoneIcon sx={{ marginLeft: "5px" }} />}
                        </Button>
                        {/* {gfcId?.approve_by_dealor === false ? 
                            <Button className="black-btn" onClick={approveGFCAction}>Approve</Button>
                           
                        :    <Button className="black-btn" style={{color: "green"}}><DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }}/> Approved</Button>
                            
                        } */}
                        <Button 
                        //disabled={isSentFeedBack} 
                        className="white-btn" onClick={handleOpen}>Request for revision</Button>
                    </Grid>
                </Grid>
            </Box>
            <GfcRequestForRevision open={open} handleClose={handleClose} gfc={gfcId} getData={getGfcLayout}/>
        </>):(<div className="d-flex h-100"><p className="m-auto">No data found or It is not approved</p></div>)}</>
    
    );
}