import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import Body from "../../components/Body";
import graycheck from "../../assets/svg/graycheck.svg"
import treegraph from "../../assets/svg/treegraph.svg"
import { useNavigate } from "react-router-dom";
import { getInputDrawingByClusterIdApi } from "../../store/layout/getInputDrawing";
import { convertToFormData, formatDateString } from "../ConvertToFormData";
import { useDispatch, useSelector } from "react-redux";
import { outletListApi } from "../../store/layout/outletList";
import BillingAddress from "../reusable/BillingAddress";
import bookmarkdetail from "../../assets/svg/bookmarkdetail.svg"
import box from "../../assets/svg/box.svg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { viewOrderHistory } from "../../store/viewOrder/viewOrderHistory";
import { selectOutletApi } from "../../store/viewOrder/selectOutlet";
import ViewQuotation from "../reusable/ViewQuotation";
import { cancelOrderApi } from "../../store/orders/cancelOrder";
import { showToast } from "../../toast/toast";
import CancelOrderModal from "../reusable/CancelOrderModal";
import { set } from "lodash";
import OrderStatusModal from "./OrderStatusModal";
import { createHtmlByArrayElement, getDealerApproval, getLastElement } from "../../utils/arrayManipulation";
import AcceptOrRejectRefundModal from "../reusable/AcceptOrRejectRefundModal";
import { convertToDateFormatYearWithTwoDigits } from "../../utils/dateFormater";

function OrderHistory() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(1)
  const [selectedClustorInfo, setSelectedClusterInfo] = useState()
  const [selectedCluster, setSelectedCluster] = useState()
  const [isHide, setIsHide] = useState()
  const [uploadLayout, setUploadLayout] = useState()
  const [category, setCategory] = useState("")

  const [clusterList, setClusterList] = useState([])
  const { loading } = useSelector(state => state?.outletListReducer)
  const order = useSelector((state) => state?.selectOutletReducer?.result);
  const [isBillingAddressOpen, setIsBillingAddressOpen] = useState(false);
  const [viewQuotation, setViewQuotation] = useState(false)
  const outlet_id = useSelector(state => state?.outletListReducer?.result[0]?.id);
  const [expanded, setExpanded] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [viewOrder, setViewOrder]= useState()

  useEffect(()=>{
    setViewOrder(order)
  }, [order])

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  // const handleViewInvoice = (order) => {
  //   setSelectedOrder(order);
  //   localStorage.setItem("selectedOrder", JSON.stringify(order));
  //   navigate('/view-Order/viewBill', { state: { selectedOrder: order } });
  // };

  const handleViewInvoice = (order) => {
    setSelectedOrder(order);
    localStorage.setItem("selectedOrder", JSON.stringify(order));
    localStorage.setItem("selectedCluster", selectedCluster);
    localStorage.setItem("selectCategory", category);
    navigate('/view-Order/viewBill', { state: { selectedOrder: order, selectedCluster } });
};

useEffect(() => {
  const savedCluster = localStorage.getItem("selectedCluster");
  if (savedCluster) {
      setSelectedCluster(savedCluster);
      localStorage.removeItem("selectedCluster");

  }
}, []);


useEffect(() => {
  const selectCategory = localStorage.getItem("selectCategory");
  if (selectCategory) {
      setCategory(selectCategory);
      localStorage.removeItem("selectCategory");
  }
}, [])


  function handleBillingAddressClose() {
    setIsBillingAddressOpen(false);
  }

  const handleQuotationOpen = (order) => {
    setSelectedOrder(order);
    setViewQuotation(true);
  };

  const handleQuotationClose = () => {
    setViewQuotation(false);
    setSelectedOrder(null);
    getInputDrawing()
  };

  const getInputDrawing = async () => {
    try {
      const response = await dispatch(selectOutletApi({ outlet_id: selectedCluster }))
      if (response?.payload) {
        setSelectedTab(1);
        setIsHide(true)
        setUploadLayout(true)
      } else {
        setIsHide(false)
        setSelectedTab();
        setUploadLayout(false)
      }
    } catch (error) {
    }
  }

  const setCategoryByCategory = (category) => {
    setSelectedCluster();
    setCategory(category);
    setSelectedTab("")
    setIsHide("")
    // dispatch(getInputDrawingByClusterIdApi())
  }

  const changeClusterId = (id) => {
    // dispatch(selectOutletApi({ outlet_id: outlet_id }))
    setSelectedCluster(id)
  }

  useEffect(() => {
    if (selectedCluster) {
      getInputDrawing()
    }
  }, [selectedCluster])

  useEffect(() => {
    setTimeout(() => {
      if (selectedCluster) {
        const filterCluster = clusterList?.find((item) => +item?.id === selectedCluster);
        setSelectedClusterInfo(filterCluster)
      }
    }, 2000)

  }, [selectedCluster])

  useEffect(() => {
    setClusterList([]);
    const fetchData = async () => {
      const payload = new FormData();
      payload.append('facility_category', category);
      try {
        const response = await dispatch(outletListApi(payload));
        setClusterList(Array.isArray(response.payload) ? response.payload : []);
      } catch (error) {
        console.error("Error fetching outlet list:", error);
        setClusterList([]);
      }
    };
    fetchData();
  }, [category, dispatch]);


  useEffect(() => {
    dispatch(viewOrderHistory());
  }, [dispatch]);

  const calculateTotal = (orderItems) => {
    return orderItems.reduce((total, item) => {
      if (item.price) {
        return total + (item.price);
      }
      return total;
    }, 0);
  };

  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const cancelOrder = (main_order_id) => {
      setCancelOrderId(main_order_id)
      setType('cancel')
      setCancelOrderModalOpen(true)
  }

  const refundRequest = (main_order_id) => {
    setCancelOrderId(main_order_id)
    setType('refund')
    setCancelOrderModalOpen(true)
}

  const handleCloseCancelOrderModal = () => {
      setCancelOrderId(null)
      setType(null);
      setCancelOrderModalOpen(false)
  }

  const [openModal, setOpenModal] = useState(false);
  const viewDispatchStatus = (orderData) => {
    setSelectedOrder(orderData);
    setOpenModal(true);
  }
  const handleModalClose = () =>{
    setSelectedOrder(null);
    setOpenModal(false);
  } 

  const [orderDetails, setOrderDetails] = useState([]);
  const [openAcceptRefundModal , setOpenAcceptRefundModal] = useState(false);
  const acceptOrRejectRefund = (order_id, order_details) => {
    setCancelOrderId(order_id)
    setOrderDetails(order_details)
    setOpenAcceptRefundModal(true)
  }

  const handleCloseAcceptRefundModal = () => {
    setCancelOrderId(null)
    setOpenAcceptRefundModal(false)
    setOrderDetails([])
  }
  return (
    <>
      <Body>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack
              direction="row"
              spacing={10}
              sx={{ my: 2 }}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack direction="row" alignItems="center">
                <img src={box} />
                <Typography
                  color={"#05141F"}
                  fontSize={"17px"}
                  fontWeight={400}
                  marginInlineStart={"10px"}
                  fontFamily={"'KiaSignatureRegular' !important"}
                >
                  View Orders
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card sx={{ borderRadius: "8px" }}>
              <Typography variant="h6" className="fs-17  fw-600">Select Category</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                  <Box className="greencard rightseperator p-relative" sx={{ maxWidth: "152px" }}>
                    <Box sx={{ padding: "16px 11px" }}>
                      <Box className="d-flex">
                        <Box sx={{ padding: "4px 6px 6px 0" }}>
                          <img src={graycheck} />
                        </Box>
                        <Box>
                          <Typography variant="h6" className="fs-17  fw-600">My Network</Typography>
                          <img src={treegraph} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="d-flex" sx={{ gap: "12px", paddingLeft: "7.5px", overflow: "auto" }}>
                    <Button className={`select-btn my-auto text-capitalize ${category === "New Facility" ? "active" : ""}`} onClick={() => setCategoryByCategory("New Facility")}>
                      New Facility
                    </Button>
                    <Button className={`select-btn my-auto text-capitalize ${category === "Enhancement" ? "active" : ""}`} onClick={() => setCategoryByCategory("Enhancement")} >
                      Enhancement
                    </Button>
                    <Button className={`select-btn my-auto text-capitalize ${category === "SI Upgrade" ? "active" : ""}`} onClick={() => setCategoryByCategory("SI Upgrade")}>

                      SI 2.0

                      upgrade
                    </Button>
                    <Button className={`select-btn my-auto text-capitalize ${category === "i2s-3s" ? "active" : ""}`} onClick={() => setCategoryByCategory("i2s-3s")}>

                      {/* {'i2S->3S'}
                    
                      upgrade */}
                      <span className="d-flex">
                        <span className="text-lowercase" style={{minWidth:"max-content"}}>{'i2s->'}</span><span>3s</span>
                      </span>
                    </Button>
                    <Button className={`select-btn my-auto ${category === "i2s-ECO" ? "active" : ""}`} onClick={() => setCategoryByCategory("i2s-ECO")}>
                      <span className="d-flex">
                        <span className="text-lowercase" style={{minWidth:"max-content"}}>{'i2s->'}</span><span>ECO</span>
                      </span>
                    </Button>
                    <Button className={`select-btn my-auto text-capitalize ${category === "EV Transition" ? "active" : ""}`} onClick={() => setCategoryByCategory("EV Transition")}>
                      EV Transition
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Divider sx={{ border: "1px solid #CBE6FC !important" }} orientation="vertical" variant="middle" flexItem />
                </Grid>
            
                  <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex flex-wrap" sx={{ gap: "15px" }}>
                  {loading && <CircularProgress />}
                  {!loading && clusterList?.length === 0 && (
                    <Typography variant="h6" className="fs-13 fw-400">
                      No outlet available
                    </Typography>
                  )}
                  {!loading && clusterList?.map((i) => (
                    <Box
                      style={{ cursor: "pointer" }}
                     
                      className={`greencard ${selectedCluster == i.id ? "active" : ""}`} 
                      key={i.id}
                      onClick={() => changeClusterId(i.id)}
                    >
                      <Box className="d-flex" sx={{ height: "100%" }}>
                        <Box sx={{ padding: "16px 11px" }} className="content d-flex">
                          <Box sx={{ padding: "4px 6px 6px 0" }}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8 0.5C3.86557 0.5 0.5 3.86557 0.5 8C0.5 12.1344 3.86557 15.5 8 15.5C12.1344 15.5 15.5 12.1344 15.5 8C15.5 3.86557 12.1344 0.5 8 0.5ZM8 1.86176C11.3974 1.86176 14.1389 4.60261 14.1389 8C14.1389 11.3974 11.3974 14.1356 8 14.1356C4.6026 14.1356 1.86442 11.3974 1.86442 8C1.86442 4.60261 4.6026 1.86176 8 1.86176ZM11.3981 5.26383C11.2202 5.26859 11.0513 5.34267 10.9273 5.47026L7.31812 9.07942L5.75526 7.51656C5.69243 7.45112 5.61717 7.39887 5.53389 7.36288C5.45062 7.32688 5.361 7.30787 5.27028 7.30695C5.17956 7.30603 5.08957 7.32321 5.00558 7.3575C4.92159 7.39179 4.84528 7.4425 4.78113 7.50665C4.71698 7.5708 4.66628 7.64711 4.63199 7.7311C4.59769 7.81509 4.58051 7.90508 4.58143 7.9958C4.58235 8.08651 4.60137 8.17613 4.63736 8.25941C4.67335 8.34269 4.7256 8.41795 4.79104 8.48078L6.83734 10.5264C6.96523 10.6542 7.13864 10.7261 7.31945 10.7261C7.50027 10.7261 7.67368 10.6542 7.80156 10.5264L11.8908 6.43714C11.9902 6.34168 12.0582 6.21839 12.0861 6.08349C12.114 5.9486 12.1004 5.80842 12.047 5.68142C11.9937 5.55441 11.9031 5.44654 11.7873 5.372C11.6715 5.29746 11.5358 5.25975 11.3981 5.26383Z"
                                fill="#2A3A46"
                                fill-opacity="0.4"
                              />
                            </svg>
                          </Box>
                          <Box>
                            <Typography variant="h6" className="fs-13 fw-400">
                              {i?.trade_name}
                            </Typography>
                            <Typography variant="h6" className="fs-13 fw-400">
                              {i?.city_name}
                            </Typography>
                            <Typography variant="h6" className="fw-600">
                              {i?.facility_type_name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="side-content d-flex bg-light-blue">
                          <Box className="flex-column m-auto text-center">
                            <Typography className="fs-20">0%</Typography>
                            <Typography className="fs-12 fw-800">Completed</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {uploadLayout === true && isHide === true && (
            <>
              {viewOrder.map((order) => (
                <Grid item xs={12} sm={12} md={12} lg={12} className="mt-15" key={order.id}>
                  <Card className="order-card">
                    <CardHeader
                      avatar={
                        <Box sx={{ borderRight: '1px solid #D4E0EC !important', paddingRight: '10px' }}>
                          <Typography variant="h5" className="fs-15 fw-400">
                            {order?.ordered_by_user_name}
                          </Typography>
                          <Typography variant="h5" className="fs-15 fw-400">
                            {order?.cluster_name}
                          </Typography>
                        </Box>
                      }
                      action={
                        <Box className="flex-wrap gap-10">
                          {/* <Button
                            className="white-btn"
                            sx={{
                              padding: '10px 4px 10px 4px !important',
                              minWidth: '110px !important',
                              fontSize: '13px !important',
                              gap: '5px',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => handleCancelOrder(order?.id)}
                          >
                         {order.order_cancelled == true ? "Order Cancelled" : "Order Cancellation"}
                          </Button> */}


                          {/* <Button
                            className="white-btn"
                            sx={{
                              padding: '10px 4px 10px 4px !important',
                              minWidth: '110px !important',
                              fontSize: '13px !important',
                              gap: '5px',
                              textTransform: 'capitalize',
                            }}
                          >
                            Update
                          </Button> */}

                          {/* <Button
                            className="black-btn"
                            sx={{
                              padding: '10px 4px 10px 4px !important',
                              minWidth: '110px !important',
                              fontSize: '13px !important',
                              gap: '5px',
                              textTransform: 'capitalize',
                            }}
                          >
                            Download
                          </Button> */}
                        </Box>
                      }
                      title="Main Order ID"
                      subheader={order?.main_order_id}
                      sx={{px:0}}
                    />
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{mb:"15px"}}>
                            
                    {order?.order_status && (
                            <Box className="d-flex gap-10" sx={{alignItems:"center"}} >
                              <Typography variant="label" fontWeight={600}>Order Status:</Typography>
                              <Typography variant="label" fontWeight={600} sx={{backgroundColor: "#157DC9 !important",p:"4px 8px",border:"1px solid #cad2d3",color:"#ffffff",borderRadius:"6px"}}>{order?.order_status}</Typography>
                              </Box>
                        )}
                            
                        {order?.order_cancellation_comment && (
                          <Box className="d-flex gap-10" sx={{mt:"10px",alignItems:"center"}} >
                          <Typography variant="label" fontWeight={600}>Cancellation Reason:</Typography>
                          <Typography variant="label">{order?.order_cancellation_comment}</Typography>
                          </Box>
                        )}
                            

                        </Grid>
                      
                      <Button
                      className="black-btn"
                      sx={{ fontSize: "13px !important",mr:"10px" }}
                      onClick={() => cancelOrder(order?.id)}
                      disabled={order?.order_cancelled || order?.is_remark || order?.not_cancellable}
                    >{order?.order_cancelled == true ? "Order Cancelled" : "Cancel Order"}

                    </Button>
                      
                    
                      {order?.order_cancelled === true && order?.payments?.length > 0 && order?.order_refund_details?.length ===0 && (
                          <>
                            <Button
                                className={order?.refund_initiated ? 'black-btn my-auto fw-600' : 'white-btn my-auto fw-600'}
                                sx={{ fontSize: "12px !important" ,mr:"10px" }}
                                onClick={() => refundRequest(order?.id)}
                                disabled={order?.refund_initiated}
                            >
                                {order?.refund_initiated ? "Refund Initiated" : "Request Refund"}
                            </Button>
                          </>
                      )} 

                      {order?.order_cancelled === true &&  order?.order_refund_details?.length > 0 && order?.refund_paid_by_innoceans===false && (
                          <>
                            <Button
                                className={order?.refund_initiated ? 'black-btn my-auto fw-600' : 'white-btn my-auto fw-600'}
                                sx={{ fontSize: "12px !important" ,mr:"10px" }}
                                onClick={() => refundRequest(order?.id)}
                                disabled={order?.refund_initiated}
                            >
                                Refund Disapproved
                            </Button>
                          </>
                      )} 
                      {order?.refund_paid_by_innoceans && getDealerApproval(order?.order_refund_details)===false &&(
                          <>
                            <Button
                                className={order?.order_refund_details?.length > 0 ? 'black-btn my-auto fw-600' : 'white-btn my-auto fw-600'}
                                sx={{ fontSize: "12px !important" ,mr:"10px" }}
                                onClick={() => acceptOrRejectRefund(order?.id, getLastElement(order?.order_refund_details))}
                                //disabled={order?.refund_initiated}
                                title="Click to approve or reject the refund"
                            >
                                Refund Paid
                            </Button>
                          </>
                      )} 

                      {order?.refund_paid_by_innoceans && getDealerApproval(order?.order_refund_details)===true &&(
                          <>
                            <Button
                                className={order?.order_refund_details?.length > 0 ? 'black-btn my-auto fw-600' : 'white-btn my-auto fw-600'}
                                sx={{ fontSize: "12px !important" ,mr:"10px" }}
                                disabled
                                title="You have accepted the refund"
                            >
                                Refund Accepted
                            </Button>
                          </>
                      )}  
                    

                    <Button
                      className="black-btn"
                      sx={{ fontSize: "13px !important" }}
                      onClick={() => viewDispatchStatus(order)}
                    >View Dispatch Status</Button>
                    
                    
                    <CardContent sx={{px:0}}>
                      <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none !important' }}>
                        <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                              <TableRow>
                                <TableCell className="cstm-tablecell1">Order Date</TableCell>
                                <TableCell className="cstm-tablecell2">{formatDateString(order?.order_date)}</TableCell>
                                <TableCell className="cstm-tablecell1">Order Total</TableCell>
                                <TableCell className="cstm-tablecell2">₹ {calculateTotal(order?.order_items)} ({order?.order_items?.length} Material)</TableCell>
                              </TableRow>
                              <TableRow>
                              <TableCell className="cstm-tablecell1">Payment</TableCell>
                              <TableCell className="cstm-tablecell2">  {order?.payments[0]?.payment_completed ? 'Completed' : 'Incomplete'}</TableCell>
                                <TableCell className="cstm-tablecell1">Payment on</TableCell>
                                <TableCell className="cstm-tablecell2">{order?.payments[0]?.payment_on ? convertToDateFormatYearWithTwoDigits(order?.payments[0]?.payment_on) : "NA"}</TableCell>
                              </TableRow>
                             
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </CardContent>
                    <CardActions disableSpacing className="gap-10" sx={{px:0}}>
                      <Button
                        className="orange-btn fs-11"
                        sx={{
                          padding: '10px 18px 10px 18px !important',
                          minWidth: '110px !important',
                          fontSize: '13px !important',
                          gap: '5px',
                          textTransform: 'capitalize',
                        }}
                        disabled={order?.order_cancelled}
                        onClick={() => handleViewInvoice(order)}
                      >
                        <img src={bookmarkdetail} alt="bookmark detail" /> View Invoice & Bill Receipt
                      </Button>
                      <Button
                        className="orange-btn fs-11"
                        sx={{
                          padding: '10px 18px 10px 18px !important',
                          minWidth: '110px !important',
                          fontSize: '13px !important',
                          gap: '5px',
                          textTransform: 'capitalize',
                        }}
                        disabled={order?.order_cancelled}
                        onClick={() => handleQuotationOpen(order)}
                      >
                        <img src={bookmarkdetail} alt="bookmark detail" /> View Quotation
                      </Button>
                      <Button
                        className="fs-12 btn-no-property"
                        sx={{
                          marginLeft: 'auto',
                          textTransform: 'capitalize',
                          background: 'none !important',
                          color: '#000000 !important',
                        }}
                        expand={expanded[order.id] ? 'true' : 'false'}
                        onClick={() => handleExpandClick(order.id)}
                        aria-expanded={expanded[order.id] ? 'true' : 'false'}
                      >
                        {expanded[order.id] ? 'Show less' : 'Show more'}
                        <ExpandMoreIcon
                          className="fs-12"
                          sx={{ color: '#000000' }}
                          style={{
                            transform: expanded[order.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                          }}
                        />
                      </Button>
                    </CardActions>
                    {order?.order_items?.map((item, index) => (
                      <Collapse in={expanded[order.id]} timeout="auto" unmountOnExit key={index}>
                        <CardContent>
                          <Grid container sx={{ borderBottom: '3px solid #E6F1FC', paddingBlock: '15px !important' }}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Box>
                              {item?.product_snap ? <img height={172} className="mx-auto" style={{ width: 'auto', maxWidth: "90%", objectFit: 'contain' }} src={item?.product_snap} alt="Product" /> : "No Image"}
                                
                                {/* <Box className="d-flex">
                                  <Typography variant="h5" className="fs-14">
                                    Order ID: &nbsp;
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    className="fs-14 fw-600"
                                    sx={{ color: '#157DC9 !important' }}
                                  >
                                    {item?.sub_order_id}
                                  </Typography>
                                </Box> */}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                              <Box className="d-flex w-100">
                                {/* <Box className="w-50">
                                  <Box className="d-flex w-100">
                                    <Box
                                      className="cstm-tablecell1 w-50"
                                      sx={{ borderBottom: '1px solid #D3DCE6 !important ' }}
                                    >
                                      Order Status
                                    </Box>
                                    <Box
                                      className="cstm-tablecell2 w-50"
                                      sx={{ borderBottom: '1px solid #D3DCE6 !important ' }}
                                    >
                                      -
                                    </Box>
                                  </Box>
                                  <Box className="d-flex w-100">
                                    <Box className="cstm-tablecell1 w-50">Manufacturer/Vendor</Box>
                                    <Box className="cstm-tablecell2 w-50">{item?.supplier}</Box>
                                  </Box>
                                </Box>
                                <Box className="w-50">
                                  <Box className="d-flex w-100">
                                    <Box
                                      className="cstm-tablecell1 w-50"
                                      sx={{ borderBottom: '1px solid #D3DCE6 !important ' }}
                                    >
                                      Qty
                                    </Box>
                                    <Box
                                      className="cstm-tablecell2 w-50 no-border"
                                      sx={{ borderBottom: '1px solid #D3DCE6 !important ' }}
                                    >
                                      {item?.quantity}
                                    </Box>
                                  </Box>
                                  <Box className="d-flex w-100">
                                    <Box className="cstm-tablecell1 w-50">ETA</Box>
                                    <Box className="cstm-tablecell2 w-50 no-border">
                                      - {item?.product_description}
                                    </Box>
                                  </Box>
                                </Box> */}

                                <table className='w-100' rules='all'>
                                  <tbody>
                                    <tr>
                                      <td className='w-50'>
                                        <Box className="w-100 pb-16">
                                          <Box className="d-flex w-100">
                                            <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}} >
                                              {/* Order Status */}
                                              Sub Order ID
                                            </Box>
                                            <Box className="w-50 fw-600">
                                              : {item?.sub_order_id}

                                            </Box>
                                          </Box>

                                        </Box>
                                      </td>
                                      <td className='w-50'>
                                        <Box className="w-100 pb-16">
                                          <Box className="d-flex w-100">
                                            <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
                                              {/* Qty */}
                                              Order Status
                                            </Box>
                                            <Box className="w-50 no-border fw-400">

                                              : {item?.sub_order_status}

                                            </Box>
                                          </Box>

                                        </Box>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='w-50'>
                                        <Box className="w-100 pt-16 pb-16">

                                          <Box className="d-flex w-100">
                                            <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
                                              Manufacturer/Vendor

                                            </Box>
                                            <Box className="w-50 fw-400">
                                              : {item?.supplier}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </td>
                                      <td className='w-50'>
                                        <Box className="w-100 pt-16 pb-16">

                                          <Box className="d-flex w-100">
                                            <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
                                              Qty
                                            </Box>
                                            <Box className="w-50 no-border fw-400">
                                              : {item?.quantity}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </td>
                                    </tr>
                                    {/* {createHtmlByArrayElement(item?.order_status) } */}
                                  </tbody>
                                </table>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Collapse>
                    ))}
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Body>
      <ViewQuotation open={viewQuotation} handleClose={handleQuotationClose} viewOrder={selectedOrder} />
      <BillingAddress open={isBillingAddressOpen} handleClose={handleBillingAddressClose} />
      <CancelOrderModal open={cancelOrderModalOpen} handleClose={handleCloseCancelOrderModal} mainOrderId={cancelOrderId } type={type} selectedCluster={selectedCluster}/>
      <OrderStatusModal open={openModal} onClose={handleModalClose} selectedOrder={selectedOrder} />
      <AcceptOrRejectRefundModal open={openAcceptRefundModal} handleClose={handleCloseAcceptRefundModal} mainOrderId={cancelOrderId} orderDetails={orderDetails} selectedCluster={selectedCluster} />
    </>
  )
}
export default OrderHistory
