import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoWhite from "../assets/img/kialogowhite.png";
import Style from "../styles/drawer.module.scss";
import SpeedIcon from "@mui/icons-material/Speed";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BorderClear, Padding } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { isHandleDrawer } from "../store/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Height, TextSnippetRounded } from "@mui/icons-material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { logoutApi } from "../store/auth/login";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles({
  menuUnstyledList: {
    listStyle: "none",
    listStylePosition: "inside",
    marginInlineStart: 20,
    marginBlock: 0,
    paddingBottom: 10,
    "& li": {
      padding: "8px 0",
      "& a": {
        opacity: 0.5,
        fontSize: 14,
      textDecoration: "none",
        color: "#fff",
        "&:hover": {
          opacity: 1,

        },
      },
      "&.active a": {
        opacity: 1,
        textDecoration: "none"
      },
    },
  },
});


export default function Drawer() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const {result:isHamburgerOpen}= useSelector((state) => state.drawerTypeReducer);

  const expandIconStyle = {
    ml: 7,
  };

  useEffect(() => {
    // Split the pathname into segments
    const segments = pathname.split("/").filter(Boolean);


    // Get the first segment of the URL path
    const firstSegment = segments[0];

    // Determine which accordion to expand based on the first segment
    if (firstSegment === "dealer") {
      setExpandedAccordion("dealerAccordion");
    } else if (firstSegment === "v") {
      setExpandedAccordion("phaseControllerAccordion");
    } else if (
      firstSegment === "layout" ||
      firstSegment === "order" ||
      firstSegment === "view-order"
    ) {
      setExpandedAccordion("userManagementAccordion");
    }
  }, [pathname]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const closeHamburger = () => {
    dispatch(isHandleDrawer(!isHamburgerOpen))
  }
  const handleLogout = async () => {
    const newState = false;
    dispatch(isHandleDrawer(newState))
    dispatch(logoutApi())
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>

{isMobile ? (
        <Box className={isHamburgerOpen === true ? Style?.open : Style?.close}>
          <Box className={Style?.logo} onClick={() => navigate("/")}>
            <img src={LogoWhite} alt="logo"  onClick={closeHamburger}/>
            <HighlightOffOutlinedIcon className={Style.closemobile} sx={{Height: "30px", Width: "30px",color:"#ffffff"}} onClick={closeHamburger}/>
          </Box>
          <List sx={{ width: "100%", fontSize: "15px", fontWeight: "400" }}>
          <ListItem className="text-center" sx={{color:"#ffffff !important",justifyContent:"center",fontWeight: "600", fontSize: "17px", marginBottom: "10px"}}>
            New Dealer Onboarding Portal
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname === "/"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "userManagementAccordion"}
              onChange={handleAccordionChange("userManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="userManagementAccordion-content"
                id="userManagementAccordion-header"
              >
                <DriveFileMoveIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  CI/SI 2.0 Order Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={classes.menuUnstyledList}>
                  <li onClick={closeHamburger} className={
                      pathname === "/layout" ? "active" : ""
                    }>
                    <Link to="/layout">
                      Layout Management
                    </Link>
                  </li>
                  <li onClick={closeHamburger} className={
                      pathname === "/order" ? "active" : ""
                    }>
                    <Link to="/order">
                    Create Purchase Order 
                    </Link>
                  </li>
                  <li onClick={closeHamburger} className={
                      pathname === "/view-order" ? "active" : ""
                    } >
                    <Link to="/view-order">
                      View Orders
                    </Link>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>       
          <ListItem
            className={
              pathname === "/user/profile"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/user/profile"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dealer Profile"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

     
        </List>
      </Box>
        
      ) : (
      <Box className={Style.open}>
        <Box className={Style?.logo} onClick={() => navigate("/")}>
          <img src={LogoWhite} alt="logo" />
        </Box>
        <Typography className="text-center" sx={{color:"#ffffff !important",fontWeight: "600", fontSize: "17px", marginBottom: "10px"}}>
            New Dealer Onboarding Portal
          </Typography>
        <List sx={{ width: "100%", fontSize: "15px", fontWeight: "400" }}>
             <ListItem
            className={
              pathname === "/"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
          </ListItem>
          <ListItem
            sx={{ p: 0 }}
            className={
              pathname.includes("/v") ? Style.activeLink : Style?.inActiveLink
            }
          >
            <Accordion
              expanded={expandedAccordion === "userManagementAccordion"}
              onChange={handleAccordionChange("userManagementAccordion")}
              sx={{ flexGrow: "1", width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  pl: 2,
                  pr: 2,
                  borderBottom: "1px solid rgba(42, 58, 70, 1)",
                }}
                aria-controls="userManagementAccordion-content"
                id="userManagementAccordion-header"
              >
                <DriveFileMoveIcon sx={{ mr: 1.5 }} />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "14px",
                  }}
                >
                  CI/SI 2.0 Order Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  backgroundColor: "rgba(8, 27, 41, 1)",
                  p: 0,
                  px: 4,
                }}
              >
                <ul className={classes.menuUnstyledList}>
                  <li   className={
                      pathname === "/layout" ? "active" : ""
                    }>
                    <Link 
                  
                    to="/layout"
                    >
                      Layout Management
                    </Link>
                  </li>
                  <li className={
                      pathname === "/order" || pathname === "/order/material-selection" || pathname === "/order/product-list" || pathname === "/order/place-Order" ? "active" : ""}>
                    <Link  to="/order">
                    Create Purchase Order 
                    </Link>
                    

                  </li>
                  <li  className={
                      pathname === "/view-order" || pathname === "/view-Order/viewBill" ? "active" : ""}>
                    <Link  to="/view-order">
                      View Orders
                    </Link>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </ListItem>       
          <ListItem
            className={
              pathname === "/user/profile"
                ? Style.activeLink
                : Style?.inActiveLink
            }
            disablePadding
          >
            <ListItemButton
              sx={{ borderBottom: "1px solid rgba(42, 58, 70, 1)" }}
              component={Link}
              to="/user/profile"
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dealer Profile"
                sx={{
                  ml: -1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
 
        </List>
      </Box>
      )}
    </>
  );
}