import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Box, Tooltip, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Card, CardHeader, Stack, Modal } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useDispatch, useSelector } from 'react-redux';
import { handleDownload } from './downloadFile';
import pdf from '../../assets/img/pdf.png'
import { viewPaymentApi } from '../../store/viewOrder/viewPayment';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { formatDateString } from '../ConvertToFormData';
import ModalCss from "../../styles/modal.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";


export default function ViewUploadedPayment({ open, handleClose, payment }) {
    return (
            <Modal open={open} onClose={handleClose} className={ModalCss.customModal}>
                <Card className={`modal ${ModalCss.customModal_md}`}>
                <CardHeader sx={{ textAlign: "center" }} title="View Uploaded Payment" />
                <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                <Box
                    component="div"
                    sx={{
                        maxWidth: "800px",
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                        <IconButton onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        justifyContent="center"
                        direction="column"
                        gap={2}
                        alignItems="center"
                        fontSize={"18px"}
                        boxSizing={"border-box"}
                        component={"div"}
                    ></Stack>
                
                <TableContainer component={Paper} sx={{ maxWidth: "100%", marginTop: 2 }}>
                                <Table>
                                    <TableHead sx={{display:"block"}}>
                                        <TableRow sx={{display: "table !important",
                                                width: "100%",
                                                tableLayout: "fixed"}}>
                                            <TableCell align='center' >UTR No</TableCell>
                                            <TableCell align='center'>Invoice Amount</TableCell>
                                            <TableCell align='center'>Payment Date</TableCell>
                                            <TableCell align='center'>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{maxHeight:"300px",overflowY:"auto",display:"block"}}>
                                        {payment.map((item, index) => (
                                            <TableRow key={index} sx={{display: "table !important",
                                                width: "100%",
                                                tableLayout: "fixed"}}>
                                                <TableCell align='center'>
                                                    {/* <img width={30} height={30} src={pdf} style={{ marginTop: "10px", marginBottom: "10px" }} alt="PDF File" /> */}
                                                    {item?.UTR_detail}
                                                </TableCell>
                                                <TableCell align='center'  sx={{padding:"5px !important"}}>
                                                 {item?.amount_paid}
                                                </TableCell>
                                                <TableCell align='center'  sx={{padding:"5px !important"}}>
                                                 {item?.payment_on? formatDateString(item?.payment_on):""}    
                                                </TableCell>
                                                <TableCell align='center'  sx={{padding:"5px !important"}}>
                                                <IconButton onClick={() => handleDownload(new Date() + 'Payment', item.upload_receipt)}>
                                                <DownloadForOfflineRoundedIcon className="my-auto" />
                                                </IconButton>
                                                
                                                    <IconButton
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${item.upload_receipt}`)}
                                                     //onClick={() => window.open(`${item.upload_invoice}`)}
                                                     >
                                                        <FullscreenIcon />
                                                    </IconButton>
                                                 
                                                </TableCell>
                        
                                            </TableRow>
                                        ))}
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>


                
                            </Box>
            </Card>
        </Modal>
    );
}
