import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import '../../styles/popup.scss';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { getInputDrawingByClusterIdApi } from "../../store/layout/getInputDrawing";
import { handleDownload } from "./downloadFile";
import { convertToFormData } from "../ConvertToFormData";
import { gfcLayout } from "../../store/layout/gfc";
import { schematicLayout } from "../../store/layout/schematicLayout";

const useStyles = makeStyles((theme) => ({
    bgLightGray: {
        backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
        borderRadius: "10px",
    },
    p10: {
        padding: "10px",
    },
    p20: {
        padding: "20px",
    },
    buttonsStyle: {
        backgroundColor: "#D7EDFE",
    },
    fullWidth: {
        width: "100%",
    },
    marginBottom20: {
        marginBottom: "20px",
    },
    inputGroup: {
        display: "flex",
        gap: "20px",
        marginBottom: "20px",
    },
    imageUpload: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #ccc",
        padding: "20px",
        borderRadius: "10px",
        cursor: "pointer",
    },
    imageUploadText: {
        marginTop: "10px",
        color: "#888",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}));


const ViewLayoutPlan = ({ open, handleClose }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [inputDrawingData, setInputDrawingData] = useState(null);
    const [gfc, setGfc] = useState()
    const [schematicData, setSchematicData] = useState()
    // const outlet_id = useSelector(state => state?.outletListReducer?.result[0]?.id);
    const clusterId= localStorage.getItem("selectedClusterForOrder")
    const facility_category = localStorage.getItem("category")
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);

    useEffect(() => {
        const fetchInputDrawingData = async () => {
            try {
                const response = await dispatch(getInputDrawingByClusterIdApi({ outlet_id: clusterId,"facility_category":facility_category }));
                if (response?.payload?.status === 'success') {
                    setInputDrawingData(response.payload.data); 
                } else {
                    console.error('Failed to fetch input drawing data');
                }
            } catch (error) {
                console.error('Error fetching input drawing data:', error);
            }
        };

        if (clusterId) {
            fetchInputDrawingData();
        }
    }, [dispatch, clusterId]);

    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.id
        };
        const formData = convertToFormData(formD);
        try {
            const response = await dispatch(gfcLayout(formData));
            if (response?.payload?.status === 'success') {
                setGfc(response.payload.data);
            } else {
                console.error('Failed to fetch input drawing data');
            }

        } catch (error) {
        }
    };
    
    useEffect(() => {
        if (formId?.id) {
            getGfcLayout();
        }
    }, [formId]);

    const getSchematicData = async () => {
        const formD = {
            'form_id': formId?.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(schematicLayout(data));

            setSchematicData(response.payload.data);
            // setData(response?.payload?.data);
        } catch (error) {
        }
    }

    useEffect(() => {
        getSchematicData();
    }, []);


    return (
        <>
            <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
                <Card className={`modal popup ${ModalCss?.customModal_md} ${ModalCss?.billingmodal}`}>
                    <CardHeader
                        className="popup-header-rev pt-0 pb-0 p-0"
                        action={
                            <IconButton onClick={handleClose}>
                                <HighlightOffIcon />
                            </IconButton>
                        }
                        title={
                            <Box>
                                Layout Planning
                            </Box>
                        }
                    />
                    <CardContent sx={{ paddingInline: { md: "7rem", xs: "1rem" },maxHeight:400,overflowY: "auto" }}>
                        <Typography variant="h3" className="fs-15 mb-10">Input Drawing</Typography>
                        <Box className="d-flex flex-wrap gap-10 mt-10" sx={{ width: "100%" }}>
                            {inputDrawingData?.input_pdf && inputDrawingData.input_pdf.length > 0 ? (
                                inputDrawingData.input_pdf.map((schematicLayoutData, index) => (
                                    <Box key={index} className="bg-white text-center">
                                        <Box className="m-auto p-relative">
                                            <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                <img width={40} height={40} src={pdf} className="my-auto" alt="PDF File" />
                                                <Typography>PDF File</Typography>
                                                <DownloadForOfflineRoundedIcon
                                                    className="my-auto"
                                                    onClick={() => handleDownload(new Date() + 'inputdrawing_pdf', schematicLayoutData.files)}
                                                />
                                                <FullscreenIcon
                                                    className="my-auto"
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData?.files}`)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No PDF data found</Typography>
                            )}
                        </Box>

                        <Box className="d-flex flex-wrap gap-10 mt-10 mb-20" sx={{ width: "100%" }}>
                            {inputDrawingData?.input_cad && inputDrawingData.input_cad.length > 0 ? (
                                inputDrawingData.input_cad.map((schematicLayoutData, index) => (
                                    <Box key={index} className="bg-white text-center">
                                        <Box className="m-auto p-relative">
                                            <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                <img width={40} height={40} src={cad} className="my-auto" alt="CAD File" />
                                                <Typography>CAD File</Typography>
                                                <DownloadForOfflineRoundedIcon
                                                    className="my-auto"
                                                    onClick={() => handleDownload(new Date() + 'inputdrawing_cad', schematicLayoutData.files)}
                                                />
                                                <FullscreenIcon
                                                    className="my-auto"
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData?.files}`)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No CAD data found</Typography>
                            )}
                        </Box>

                        <Typography variant="h3" className="fs-15 mb-10">Interior Photos</Typography>

                        {/* <Typography variant="h6" className="fs-13 " sx={{ marginTop: "22px" }}>Interior Photos</Typography> */}
                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "15px" }}>
                            {inputDrawingData?.layout_interior && inputDrawingData.layout_interior.length > 0 ? (
                                inputDrawingData.layout_interior.map((interiorPhotos, index) => (
                                    <Box key={index} sx={{ width: "213px" }}>
                                        <img
                                            className="border-radious-14"
                                            style={{ width: "198px", height: '181px' }}
                                            src={`${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`}
                                            alt="Interior Photo"
                                        />
                                        <Box className="text-center justify-center d-flex" sx={{ gap: "7px", marginTop: "15px" }}>
                                            <Box className="my-auto">
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.639 0.943359H7.414V1.66811H9.76888L6.67578 4.77787L7.18527 5.29042L10.2787 2.18051V4.54856H10.9993V1.30573L10.639 0.943359Z" fill="#05141F" />
                                                    <path d="M3.81345 7.6543L0.720633 10.7645V8.3963H0V11.6397L0.360316 12.0021H3.58472V11.2773H1.22998L4.32308 8.16684L3.81345 7.6543Z" fill="#05141F" />
                                                    <path d="M0.72121 4.54856L0.720777 2.17616L3.81316 5.291L4.32308 4.77874L1.23473 1.66811H3.58529V0.943359H0.360316L0 1.30588L0.000576602 4.54871L0.72121 4.54856Z" fill="#05141F" />
                                                    <path d="M10.2779 10.7586L7.18498 7.65234L6.67578 8.16518L9.77277 11.2755L7.41328 11.276L7.41342 12.0007L10.6384 12.0001L10.9986 11.6377V8.39492H10.2779V10.7586Z" fill="#05141F" />
                                                </svg>
                                            </Box>
                                            <Button 
                                            className="fs-12 link-btn"
                                                sx={{ fontSize: "12px !important" }}
                                                onClick={() => {
                                                      const imageUrl = `${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`;
                                                      const newWindow = window.open();
                                                      newWindow.document.write(`<img src="${imageUrl}" alt="Full View" style="width:100%;height:auto;">`);
                                                    newWindow.document.close();
                                                }}
                                            >Full View</Button>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No interior photos found</Typography>
                            )}
                        </Box>

                        <Typography variant="h3" className="fs-15 mt-20 mb-10">Exterior Photos</Typography>


                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "15px" }}>
                            {inputDrawingData?.layout_exterior && inputDrawingData.layout_exterior.length > 0 ? (
                                inputDrawingData.layout_exterior.map((exteriorPhotos, index) => (
                                    <Box key={index} sx={{ width: "213px" }}>
                                        <img
                                            className="border-radious-14"
                                            style={{ width: "198px", height: '181px' }}
                                            src={`${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`}
                                            alt="Exterior Photo"
                                        />
                                        <Box className="text-center justify-center d-flex" sx={{ gap: "7px", marginTop: "15px" }}>
                                            <Box className="my-auto">
                                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.639 0.943359H7.414V1.66811H9.76888L6.67578 4.77787L7.18527 5.29042L10.2787 2.18051V4.54856H10.9993V1.30573L10.639 0.943359Z" fill="#05141F" />
                                                    <path d="M3.81345 7.6543L0.720633 10.7645V8.3963H0V11.6397L0.360316 12.0021H3.58472V11.2773H1.22998L4.32308 8.16684L3.81345 7.6543Z" fill="#05141F" />
                                                    <path d="M0.72121 4.54856L0.720777 2.17616L3.81316 5.291L4.32308 4.77874L1.23473 1.66811H3.58529V0.943359H0.360316L0 1.30588L0.000576602 4.54871L0.72121 4.54856Z" fill="#05141F" />
                                                    <path d="M10.2779 10.7586L7.18498 7.65234L6.67578 8.16518L9.77277 11.2755L7.41328 11.276L7.41342 12.0007L10.6384 12.0001L10.9986 11.6377V8.39492H10.2779V10.7586Z" fill="#05141F" />
                                                </svg>
                                            </Box>
                                            <Button
                                                className="fs-12 link-btn"
                                                sx={{ fontSize: "12px !important" }}
                                                onClick={() => {
                                                      const imageUrl = `${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`;
                                                      const newWindow = window.open();
                                                      newWindow.document.write(`<img src="${imageUrl}" alt="Full View" style="width:100%;height:auto;">`);
                                                    newWindow.document.close();
                                                }}
                                                //onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`)}
                                            >
                                                Full View
                                            </Button>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No exterior photos found</Typography>
                            )}
                        </Box>

                        <Typography variant="h3" className="fs-15 mt-20 mb-0">Schematic Data</Typography>
                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "5px" }}>
                            {/* Schematic PDF Section */}
                            <Box className="d-flex flex-wrap gap-10" sx={{ width: "100%" }}>
                                {schematicData?.schematic_pdf?.length > 0 ? (
                                    schematicData.schematic_pdf.map((schematicLayoutData, index) => (
                                        <Box key={index} className="bg-white text-center">
                                            <Box className="m-auto p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                    <img width={30} height={30} src={pdf} className="my-auto" alt="PDF Icon" />
                                                    <Typography>PDF File</Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_pdf', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography className="mt-10">No PDF data found</Typography>
                                )}
                            </Box>

    
                            <Box className="d-flex flex-wrap gap-10" sx={{ width: "100%" }}>
                                {schematicData?.schematic_cad?.length > 0 ? (
                                    schematicData.schematic_cad.map((schematicLayoutData, index) => (
                                        <Box key={index} className="bg-white text-center">
                                            <Box className="m-auto p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                    <img width={30} height={30} src={cad} className="my-auto" alt="CAD Icon" />
                                                    <Typography>CAD File</Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography className="mt-10">No CAD data found</Typography>
                                )}
                            </Box>
                        </Box>
                        <Typography variant="h3" className="fs-15 mt-20 mb-0">GFC</Typography>

                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "5px" }}>
                            {/* GFC PDF Section */}
                                <Box className="d-flex flex-wrap gap-10" sx={{ width: "100%" }}>
                            {gfc?.gfc_pdf && gfc.gfc_pdf.length > 0 ? (

                                    <Box className="bg-white text-center">
                                        <Box className="m-auto p-relative">
                                            <Tooltip title={gfc.gfc_pdf[0].files} placement="top" className="uploadedfilename">
                                                <img width={30} height={30} src={pdf} className="my-auto" alt="PDF File" />
                                                <Typography>PDF File</Typography>
                                                <DownloadForOfflineRoundedIcon
                                                    className="my-auto"
                                                    onClick={() => handleDownload(new Date() + 'gfc_pdf', gfc?.gfc_pdf[0]?.files)}
                                                />
                                                <FullscreenIcon
                                                    className="my-auto"
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${gfc?.gfc_pdf[0]?.files}`)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                            ) : (
                                <Typography className="mt-10">No PDF data found</Typography>
                            )}
                        </Box>

                            {/* GFC CAD Section */}
                                <Box className="d-flex flex-wrap gap-10" sx={{ width: "100%" }}>
                            {gfc?.gfc_cad && gfc.gfc_cad.length > 0 ? (

                                    <Box className="bg-white text-center">
                                        <Box className="m-auto p-relative">
                                            <Tooltip title={gfc.gfc_cad[0].files} placement="top" className="uploadedfilename">
                                                <img width={30} height={30} src={cad} className="my-auto" alt="CAD File" />
                                                <Typography>CAD File</Typography>
                                                <DownloadForOfflineRoundedIcon
                                                    className="my-auto"
                                                    onClick={() => handleDownload(new Date() + 'gfc_cad', gfc.gfc_cad[0].files)}
                                                />
                                                <FullscreenIcon
                                                    className="my-auto"
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${gfc.gfc_cad[0].files}`)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                            ) : (
                                <Typography className="mt-10">No CAD data found</Typography>
                            )}
                                </Box>

                        </Box>


                        
                    </CardContent>
                    <CardActions disableSpacing>
                        <Box
                            sx={{ justifyContent: "center !important", gap: "10px" }}
                            component={"div"}
                            width={"100%"}
                            marginBlockStart={0.5}
                            className={classes.buttonContainer}
                        >
                            <Button sx={{ width: "200px" }} className="black-btn" onClick={handleClose}>
                                Cancel
                            </Button>
            
                        </Box>
                    </CardActions>
                </Card>
            </Modal>
        </>
    );
};

export default ViewLayoutPlan;

