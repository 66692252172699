import React, { useEffect } from "react";
import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  Modal,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { handleDownload } from "./downloadFile";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import otherFile from '../../assets/img/file-icon.png'

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ViewDownloadModal({ open, handleClose, row }) {
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <HighlightOffIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Download
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Box>
            <Box className="m-auto p-relative">
              {row?.schematic_pdf?.map((schematicLayoutData) => (
                <Box key={schematicLayoutData.files}>
                  <Box className="m-auto p-relative">
                    <Tooltip
                      title={schematicLayoutData.files}
                      placement="top"
                      className="uploadedfilename"
                    >
                      <img
                        width={30}
                        height={30}
                        src={pdf}
                        className="my-auto"
                        alt="PDF"
                        style={{ marginRight: "3px" }}
                      />
                      <Typography>PDF File</Typography>
                      <DownloadForOfflineRoundedIcon
                        className="my-auto"
                        onClick={() =>
                          handleDownload(
                            new Date() + "schematic_pdf",
                            schematicLayoutData.files
                          )
                        }
                      />
                      <FullscreenIcon
                        className="my-auto"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`
                          )
                        }
                      />
                    </Tooltip>
                  </Box>
                </Box>
              ))}
              {row?.schematic_cad?.map((schematicLayoutData) => (
                <Box key={schematicLayoutData.files}>
                  <Box className="m-auto p-relative">
                    <Tooltip
                      title={schematicLayoutData.files}
                      placement="top"
                      className="uploadedfilename"
                    >
                      <img
                        width={30}
                        height={30}
                        src={cad}
                        className="my-auto"
                        alt="CAD"
                        style={{ marginRight: "3px" }}
                      />
                      <Typography>CAD File</Typography>
                      <DownloadForOfflineRoundedIcon
                        className="my-auto"
                        onClick={() =>
                          handleDownload(
                            new Date() + "schematic_cad",
                            schematicLayoutData.files
                          )
                        }
                      />
                      <FullscreenIcon
                        className="my-auto"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`
                          )
                        }
                      />
                    </Tooltip>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
}
